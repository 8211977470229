

export const Avatar: React.FC = () => {
    return (
        <svg
            width={264}
            height={280}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="prefix__a"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x={32}
                y={36}
                width={200}
                height={244}
            >
                <path
                    d="M156 180.611V199h4c39.765 0 72 32.235 72 72v9H32v-9c0-39.765 32.236-72 72-72h4v-18.389c-17.237-8.189-29.628-24.924-31.695-44.73C70.48 135.058 66 130.052 66 124v-14c0-5.946 4.325-10.882 10-11.834V92c0-30.928 25.072-56 56-56s56 25.072 56 56v6.166c5.675.952 10 5.888 10 11.834v14c0 6.052-4.48 11.058-10.305 11.881-2.067 19.806-14.458 36.541-31.695 44.73z"
                    fill="#fff"
                />
            </mask>
            <g mask="url(#prefix__a)">
                <path
                    d="M156 180.611V199h4c39.765 0 72 32.235 72 72v9H32v-9c0-39.765 32.236-72 72-72h4v-18.389c-17.237-8.189-29.628-24.924-31.695-44.73C70.48 135.058 66 130.052 66 124v-14c0-5.946 4.325-10.882 10-11.834V92c0-30.928 25.072-56 56-56s56 25.072 56 56v6.166c5.675.952 10 5.888 10 11.834v14c0 6.052-4.48 11.058-10.305 11.881-2.067 19.806-14.458 36.541-31.695 44.73z"
                    fill="#D0C6AC"
                />
                <path d="M296 36H32v280h264V36z" fill="#EDB98A" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M188 115v23c0 30.928-25.072 56-56 56s-56-25.072-56-56v-23 15c0 30.928 25.072 56 56 56s56-25.072 56-56v-15z"
                    fill="#000"
                    fillOpacity={0.1}
                />
            </g>
            <mask
                id="prefix__b"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x={32}
                y={183}
                width={200}
                height={97}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M108 183.071c-17.919 2.005-31.72 7.481-31.996 21.574C50.146 215.568 32 241.165 32 270.999V280h200v-9.001c0-29.834-18.146-55.431-44.004-66.354-.276-14.093-14.077-19.569-31.996-21.574V202c0 13.255-10.745 24-24 24s-24-10.745-24-24v-18.929z"
                    fill="#fff"
                />
            </mask>
            <g mask="url(#prefix__b)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M108 183.071c-17.919 2.005-31.72 7.481-31.996 21.574C50.146 215.568 32 241.165 32 270.999V280h200v-9.001c0-29.834-18.146-55.431-44.004-66.354-.276-14.093-14.077-19.569-31.996-21.574V202c0 13.255-10.745 24-24 24s-24-10.745-24-24v-18.929z"
                    fill="#B7C1DB"
                />
                <path d="M264 170H0v110h264V170z" fill="#FF4F7B" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M102 231.739V280h-7v-51.85a64.465 64.465 0 007 3.589zm67-3.589v40.35a3.5 3.5 0 11-7 0v-36.761a64.501 64.501 0 007-3.589z"
                    fill="#F4F4F4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M90.96 182.724c-15.05 2.847-25.46 8.519-25.46 19.584C65.5 222.02 98.538 238 132 238s66.5-15.98 66.5-35.692c0-11.065-10.409-16.737-25.46-19.584 9.085 3.35 14.96 8.982 14.96 18.353C188 221.469 160.179 238 132 238s-56-16.531-56-36.923c0-9.371 5.875-15.003 14.96-18.353z"
                    fill="#000"
                    fillOpacity={0.16}
                />
            </g>
            <mask
                id="prefix__c"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x={113}
                y={147}
                width={38}
                height={19}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M113.118 149.128C114.176 158.62 122.226 166 132 166c9.804 0 17.874-7.426 18.892-16.96.082-.767-.775-2.04-1.849-2.04h-33.956c-1.08 0-2.075 1.178-1.969 2.128z"
                    fill="#fff"
                />
            </mask>
            <g mask="url(#prefix__c)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M113.118 149.128C114.176 158.62 122.226 166 132 166c9.804 0 17.874-7.426 18.892-16.96.082-.767-.775-2.04-1.849-2.04h-33.956c-1.08 0-2.075 1.178-1.969 2.128z"
                    fill="#000"
                    fillOpacity={0.7}
                />
                <path
                    d="M143 136h-21a5 5 0 00-5 5v6a5 5 0 005 5h21a5 5 0 005-5v-6a5 5 0 00-5-5z"
                    fill="#fff"
                />
                <path
                    d="M127 180c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11z"
                    fill="#FF4F7B"
                />
                <path
                    d="M137 180c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11z"
                    fill="#FF4F7B"
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M120 130c0 4.418 5.373 8 12 8s12-3.582 12-8"
                fill="#000"
                fillOpacity={0.16}
            />
            <path
                d="M106 118a6 6 0 100-12 6 6 0 000 12zM158 118a6 6 0 100-12 6 6 0 000 12zM91.976 99.128c1.495-9.523 14.083-16.02 23.188-11.827a2 2 0 001.673-3.633c-11.488-5.29-26.901 2.664-28.813 14.84a2 2 0 103.952.62zM172.024 99.128c-1.495-9.523-14.082-16.02-23.187-11.827a2 2 0 11-1.673-3.633c11.487-5.29 26.9 2.664 28.812 14.84a2 2 0 11-3.952.62z"
                fill="#000"
                fillOpacity={0.6}
            />
            <mask
                id="prefix__d"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={264}
                height={280}
            >
                <path d="M264 0H0v280h264V0z" fill="#fff" />
            </mask>
            <g mask="url(#prefix__d)">
                <mask
                    id="prefix__e"
                    style={{
                        maskType: "alpha",
                    }}
                    maskUnits="userSpaceOnUse"
                    x={75}
                    y={98}
                    width={114}
                    height={89}
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M149.428 170.169c-2.513 2.294-5.191 3.325-8.575 2.604-.582-.124-2.957-4.538-8.853-4.538-5.897 0-8.271 4.414-8.853 4.538-3.385.721-6.062-.31-8.576-2.604-4.725-4.313-8.654-10.261-6.293-16.75 1.23-3.382 3.232-7.095 6.873-8.173 3.887-1.15 9.346-.002 13.264-.788 1.269-.254 2.656-.707 3.585-1.458.929.751 2.316 1.204 3.585 1.458 3.918.786 9.376-.362 13.264.788 3.641 1.078 5.642 4.791 6.873 8.173 2.361 6.489-1.568 12.437-6.294 16.75zM188.081 98c-3.41 8.4-2.093 18.858-2.724 27.676-.513 7.167-2.02 17.91-8.384 22.539-3.255 2.367-9.179 6.345-13.431 5.235-2.927-.764-3.24-9.16-7.087-12.303-4.363-3.565-9.812-5.131-15.306-4.889-2.37.104-7.165.079-9.149 1.902-1.984-1.823-6.778-1.798-9.149-1.902-5.494-.242-10.943 1.324-15.306 4.889-3.847 3.143-4.159 11.539-7.087 12.303-4.252 1.11-10.176-2.868-13.431-5.235-6.365-4.629-7.87-15.372-8.384-22.539-.63-8.818.686-19.276-2.724-27.676-1.66 0-.565 16.129-.565 16.129v20.356c.032 15.288 9.581 38.17 30.754 46.908C111.286 183.53 123.015 187 132 187s20.714-3.14 25.892-5.277c21.173-8.737 30.722-31.95 30.754-47.238v-20.356S189.74 98 188.081 98z"
                        fill="#fff"
                    />
                </mask>
                <g mask="url(#prefix__e)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M149.428 170.169c-2.513 2.294-5.191 3.325-8.575 2.604-.582-.124-2.957-4.538-8.853-4.538-5.897 0-8.271 4.414-8.853 4.538-3.385.721-6.062-.31-8.576-2.604-4.725-4.313-8.654-10.261-6.293-16.75 1.23-3.382 3.232-7.095 6.873-8.173 3.887-1.15 9.346-.002 13.264-.788 1.269-.254 2.656-.707 3.585-1.458.929.751 2.316 1.204 3.585 1.458 3.918.786 9.376-.362 13.264.788 3.641 1.078 5.642 4.791 6.873 8.173 2.361 6.489-1.568 12.437-6.294 16.75zM188.081 98c-3.41 8.4-2.093 18.858-2.724 27.676-.513 7.167-2.02 17.91-8.384 22.539-3.255 2.367-9.179 6.345-13.431 5.235-2.927-.764-3.24-9.16-7.087-12.303-4.363-3.565-9.812-5.131-15.306-4.889-2.37.104-7.165.079-9.149 1.902-1.984-1.823-6.778-1.798-9.149-1.902-5.494-.242-10.943 1.324-15.306 4.889-3.847 3.143-4.159 11.539-7.087 12.303-4.252 1.11-10.176-2.868-13.431-5.235-6.365-4.629-7.87-15.372-8.384-22.539-.63-8.818.686-19.276-2.724-27.676-1.66 0-.565 16.129-.565 16.129v20.356c.032 15.288 9.581 38.17 30.754 46.908C111.286 183.53 123.015 187 132 187s20.714-3.14 25.892-5.277c21.173-8.737 30.722-31.95 30.754-47.238v-20.356S189.74 98 188.081 98z"
                        fill="#331B0C"
                    />
                    <path d="M280 72H16v244h264V72z" fill="#4A312C" />
                </g>
                <mask
                    id="prefix__f"
                    style={{
                        maskType: "alpha",
                    }}
                    maskUnits="userSpaceOnUse"
                    x={73}
                    y={0}
                    width={118}
                    height={99}
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M113.94 28.337c-.986.222-1.954.454-2.903.693-15.092 3.808-24.02 14.621-31.68 30.618-3.761 7.855-5.991 17.143-6.334 25.833-.135 3.412.325 6.934 1.245 10.22.337 1.205 2.155 5.386 2.654 2.008.166-1.125-.442-2.676-.5-3.871-.078-1.569.005-3.157.112-4.723.2-2.928.722-5.8 1.65-8.59 1.328-3.988 3.017-8.312 5.603-11.677C90.188 60.521 94.765 43.206 132 41.671c37.235-1.535 48.749 25.79 52.183 31.63 4.065 6.914 2.195 15.406 3.436 22.9.472 2.85 1.545 2.786 2.132.237.997-4.33 1.468-8.828 1.151-13.279-.718-10.047-4.405-36.453-24.593-48.153-5.442-3.154-10.871-5.319-16.192-6.723C153.176 25.31 155 21.568 155 17.5 155 7.835 144.703 0 132 0s-23 7.835-23 17.5c0 4.093 1.846 7.857 4.94 10.837z"
                        fill="#fff"
                    />
                </mask>
                <g mask="url(#prefix__f)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M113.94 28.337c-.986.222-1.954.454-2.903.693-15.092 3.808-24.02 14.621-31.68 30.618-3.761 7.855-5.991 17.143-6.334 25.833-.135 3.412.325 6.934 1.245 10.22.337 1.205 2.155 5.386 2.654 2.008.166-1.125-.442-2.676-.5-3.871-.078-1.569.005-3.157.112-4.723.2-2.928.722-5.8 1.65-8.59 1.328-3.988 3.017-8.312 5.603-11.677C90.188 60.521 94.765 43.206 132 41.671c37.235-1.535 48.749 25.79 52.183 31.63 4.065 6.914 2.195 15.406 3.436 22.9.472 2.85 1.545 2.786 2.132.237.997-4.33 1.468-8.828 1.151-13.279-.718-10.047-4.405-36.453-24.593-48.153-5.442-3.154-10.871-5.319-16.192-6.723C153.176 25.31 155 21.568 155 17.5 155 7.835 144.703 0 132 0s-23 7.835-23 17.5c0 4.093 1.846 7.857 4.94 10.837z"
                        fill="#28354B"
                    />
                    <path d="M263 0H-1v280h264V0z" fill="#724133" />
                </g>
            </g>
        </svg>
    )
}