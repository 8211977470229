export const RingedPlanet: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            width={371}
            height={371}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <mask
                id="rp-prefix__a"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={371}
                height={371}
            >
                <path
                    d="M371 185.5C371 287.949 287.949 371 185.5 371S0 287.949 0 185.5 83.051 0 185.5 0 371 83.051 371 185.5z"
                    fill="#955BA5"
                />
            </mask>
            <g mask="url(#rp-prefix__a)">
                <path
                    d="M371 185.5C371 287.949 287.949 371 185.5 371S0 287.949 0 185.5 83.051 0 185.5 0 371 83.051 371 185.5z"
                    fill="#955BA5"
                />
                <circle cx={151.443} cy={174.631} r={23.912} fill="#B49ACA" />
                <circle cx={53.621} cy={214.484} r={15.941} fill="#B49ACA" />
                <circle cx={317.379} cy={236.223} r={15.941} fill="#B49ACA" />
                <circle cx={247.816} cy={339.117} r={40.578} fill="#B49ACA" />
                <circle cx={169.559} cy={27.535} r={73.91} fill="#B49ACA" />
                <path
                    d="M167.385 71.012c12.318-2.174 36.955-13.188 36.955-39.854"
                    stroke="#955BA5"
                    strokeWidth={10}
                    strokeLinecap="round"
                />
            </g>
            <path
                d="M14.492 342.74c-16.666-33.332 5.797-73.185 5.797-73.185s11.594 20.289 13.043 22.462c1.45 2.174 17.39 20.289 17.39 20.289s21.739.725 71.737-33.331c49.998-34.057 79.707-64.491 79.707-64.491s31.883-28.26 68.113-78.258c36.231-49.998 42.028-71.736 42.752-73.91.725-2.174.725-6.521 0-8.695-.724-2.174-5.072-7.246-5.797-7.97-.724-.725-14.492-11.594-16.666-13.044-2.173-1.449-22.463-13.043-22.463-13.043s65.215-17.39 82.606 0c17.391 17.39 10.144 47.825 10.144 47.825s-5.072 37.679-38.404 89.851-62.316 78.258-62.316 78.258-52.897 60.143-114.488 94.199C84.055 363.754 31.158 376.072 14.492 342.74z"
                fill="#FF4F7B"
            />
        </svg>
    )
}